import React from "react"

import "../css/pages/maconnerie.scss"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import {Link} from "gatsby";
import Contact from "../containers/index/contact";

const Maconnerie = () => (
  <Layout>
    <SEO title="Artisan maçon à Laon dans l'Aisne (02)"
         description={"L'entreprise Athies Batiment basée à Laon intervient pour les travaux de maçonnerie dans l'Aisne et dans la Marne. Faites appel à nos artisans maçons qualifiés."}/>
    <div className={"page maconnerie"}>
        <div className={"header"}>
            <h1 className={"title"}>Artisan maçon à Laon</h1>
        </div>

        <Section>
            <h2>Pourquoi faire appel à nos artisans ?</h2>
            <p>
                Dans le domaine de la construction ou rénovation d’habitation, la maçonnerie est le travail qui consiste à construire l’assemblage des matériaux. Plus précisément, la maçonnerie est souvent associée aux travaux d’habillage comme par exemple la pose d’enduits ou de carrelages. Ce travail permet de consolider la tenue de l’ensemble de l’habitation face aux différentes agressions (gel, humidités, etc.).
                Nos maçons interviennent dans le secteur de Laon après le nivellement du terrain lors de la construction d’un bâtiment. Tout en suivant les plans, leur travail consiste à élaborer puis mettre en place les différents éléments porteurs de la construction.
            </p>

            <p>
                Nos artisans, <strong>qualifiés et expérimentés</strong>, réaliseront vos travaux de rénovation selon vos besoins. Nous intervenons principalement dans <strong>l’Aisne (02) et dans la Marne (51)</strong>, dans le secteur proche de Laon, mais nous pouvons aussi nous déplacer dans d'autres secteurs. Pour cela, contactez-nous : <Link to={"/#contact"}>cliquez-ici</Link>.
            </p>
        </Section>

        <Contact/>
    </div>
  </Layout>
)

export default Maconnerie
